/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		  $window = $(window);
		  
		   //Ce bout de code vérifie que le consentement n'a pas déjà été obtenu avant d'afficher la bannière
		var consentCookie = getCookie('hasConsent');
		//L'utilisateur n'a pas encore de cookie de consentement
		if (!consentCookie) { askConsent(); }
	   
		   $('section[data-type="background"]').each(function(){
			 // declare the variable to affect the defined data-type
			 var $scroll = $(this);
							 
			  $(window).scroll(function() {
				// HTML5 proves useful for helping with creating JS functions!
				// also, negative value because we're scrolling upwards                             
				var yPos = -($window.scrollTop() / $scroll.data('speed')); 
				 
				// background position
				var coords = '50% '+ yPos + 'px';
		 
				// move the background
				$scroll.css({ backgroundPosition: coords });    
			  }); // end window scroll
		   });  // end section function
		   
		   $(".carousel").carousel();
		   Sage.common.onScrollInit($('.staggered-animation'), $('.staggered-animation-container1'));
		   
      },
	  onScrollInit: function( items, trigger ) {
		
		
		  items.each( function() {
			var osElement = $(this),
				osAnimationClass = osElement.attr('data-os-animation'),
				osAnimationDelay = osElement.attr('data-os-animation-delay');
		 
			osElement.css({
				'-webkit-animation-delay':  osAnimationDelay,
				'-moz-animation-delay':     osAnimationDelay,
				'animation-delay':          osAnimationDelay
			});
		 
		 	
		 
			var osTrigger = ( trigger ) ? trigger : osElement;
			
			var waypoint = new Waypoint({
			  element: osTrigger,
			  handler: function(direction) {
				osTrigger.find(osElement).addClass('animated').addClass(osAnimationClass);
			  },
			  offset : '70%'
			});
		 
			/*osTrigger.waypoint(function() {
				console.log(osTrigger);
				osElement.addClass('animated').addClass(osAnimationClass);
			},{
				triggerOnce: true,
				offset: '50%'
			});*/
		  });
		},
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
		 
		Sage.common.onScrollInit($('.staggered-animation'), $('.staggered-animation-container1'));
		Sage.common.onScrollInit($('.staggered-animation'), $('.staggered-animation-container2'));
		Sage.common.onScrollInit($('.staggered-animation'), $('.staggered-animation-container3'));
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
		
      }
    },
    // About us page, note the change from about-us to about_us.
    'hebergements': {
      init: function() {
        // JavaScript to be fired on the about us page
		Sage.common.onScrollInit($('.staggered-animation'), $('.staggered-animation-container2'));
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
